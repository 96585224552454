import './css/global.css';
import BasePage from "./components/thedogsenseBasePage"

function App() {
  return (
    <div>
      <div>
        <BasePage/>
      </div>
    </div>
  );
}

export default App;
