import React, {Component} from "react"

import spotifylogo from "../assets/podSpotifyLogo.svg"
import discordlogo from "../assets/podDiscordLogo.svg"
import podcastslogo from "../assets/podAppleLogo.svg"
import googlelogo from "../assets/podGoogleLogo.svg"
import aboutlogo from "../assets/podAboutLogo.svg"

export default class BasePage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show_pod_info:false,
            last_episode:"episode_7",
            content_episode:"episode_7",
            episode_has_feature:true,
            pod_episodes:{
                episode_7:{
                    episode_number:"7",
                    episode_title:"Booth Heads",
                    episode_date:"24/03/23",
                    episode_duration:"1H 27M",
                    episode_abstract:"As your derelict body heaves through prehistoric sludge, each subsequent relief is unceremoniously swallowed by the sediment that entrenches you. The thought of sinking into the putrid indiscriminate masses of concession collected below orbits your mind, at least serving to distract you from the laborious trudge you’ve embarked on. At last your pathetic squirming reaches its culmination as your footing fails. In that infinite expanse of catastrophe you hear whispers of Karaoke Queens, Window Shopping antics, Doctor Martens vitriol, Online Retail tactics, Dark Jester fit checks, Height Power functions, Eric Rohmer roleplay, hot Europeans, Spotify slavery, HR people, and style evolution. While caught in the swamps asphyxiating embrace you manage to gargle out a solemn 'Nikki No'.",
                    episode_featuring:["Nicole Torneiro"],
                    episode_ft_link:["https://www.instagram.com/ntorn"],
                    applecasts:"https://podcasts.apple.com/se/podcast/thedogsense/id1668189499?i=1000605801537",
                    google:"",
                    spotify:"https://open.spotify.com/episode/4NUe4Etu4yt9ay1bAXzTSh?si=79b67c2151074449"
                },
                episode_6:{
                    episode_number:"6",
                    episode_title:"Heaven or Paul Schrader",
                    episode_date:"16/03/23",
                    episode_duration:"1H 23M",
                    episode_abstract:"Emulsified in a strangely familiar slick, your thoughts aimlessly wander in disarray, unsure where or why you are. While desperately tracing through the fog a inexplicable flicker of hope relays that soon critical synaptic events will fire to bridge the gulf of confusion. In that warped corridor of time a strange irritable buzzing vibrates through your cochlea. Effervescent heckling outlines the limits of piss-soaked metropolis’s, eating fancy in New York, advanced jean hustling techniques, circumcision strategies, putting respect on tailors, Aragorn in Eastern Promises, Salmon Ego Death, the Belgians, and Mediterranean Wedding escapism before your minds valves release, expelling its sewage & smothering the disorientation. Your torso surges forward from its sweat soaked crevice, in this refound wisdom it becomes clear- that you can finally taste the blood between your teeth.",
                    episode_featuring:[],
                    episode_ft_link:[],
                    applecasts:"https://podcasts.apple.com/se/podcast/heaven-or-paul-schrader/id1668189499?i=1000604494258",
                    google:"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw/episode/N2Y1Yjg4NzUtZjMxMi00ZjI4LTlmNWYtMGJhYWM1NTE2ZGVi?sa=X&ved=0CAgQuIEEahcKEwj41PioyfX9AhUAAAAAHQAAAAAQLA",
                    spotify:"https://open.spotify.com/episode/7nmbDywWvtU3gmmT27uYn2?si=700621992bde40df"
                },
                episode_5:{
                    episode_number:"5",
                    episode_title:"Pig Juice",
                    episode_date:"07/03/23",
                    episode_duration:"1H 17M",
                    episode_abstract:"Your nostrils flare as its nasal connective tissue shrivels away from the surface membrane agitated by the all too familiar but never welcomed aroma of sterile detergent soaked halls. Incoherent howls emanate through a series of secure control doors while practiced hands dance about access pads ushering you towards the source of the mania. What lies before you possesses all cues of a care home for those afflicted by dementia. Between the penetrating shrill cries you make out conversation detailing The RealReal eating shit, Snowplow Goons totalling city bikes, leather pant dialectics, Cautionary Commonwealth announcements, French Foreign Legion musings, rise of Inceldom before your knees begin to buckle. As you stir from your catatonic state & begin to piece together what had transpired you can’t help to wonder why you’re looking down the other end of those same control doors.",
                    episode_featuring:[],
                    episode_ft_link:[],
                    applecasts:"https://podcasts.apple.com/se/podcast/thedogsense/id1668189499?i=1000603220931",
                    google:"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw/episode/YWQ2MWY1MzItMzE0OC00ZjEzLTg1N2QtYTM5ZThmYTMzZDkw?sa=X&ved=0CAgQuIEEahcKEwigp7D99MT9AhUAAAAAHQAAAAAQLA",
                    spotify:"https://open.spotify.com/episode/4rVAIIE8iOsD3uIBsxvnKj?si=dc5867d2f26a464b"
                },
                episode_4:{
                    episode_number:"4",
                    episode_title:"Anthroeconomicene",
                    episode_date:"01/03/23",
                    episode_duration:"1H 28M",
                    episode_abstract:"Always trust a fella with a bible on their heart & Blender in their taskbar, or say sayeth Peri- our tortured first guest. In holy communion we prostrate ourselves between Ali’s asinine divine gesticulations. As we flick through this seasons almanac our eyes are gilded with truths gleaned from cumulative career Psychic damage, CNC Operator grind, the art of Tray Drifting, Cabinet Guy Forum rumination, Drain Gang drooling, Furry Economy speculation, Digital Art ascension, with a bout of AI Doomsaying to list a few passages in today's telos.",
                    episode_featuring:["Peri"],
                    episode_ft_link:["https://www.instagram.com/guynoid"],
                    applecasts:"https://podcasts.apple.com/se/podcast/thedogsense/id1668189499?i=1000602384823",
                    google:"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw/episode/YWQ2MWY1MzItMzE0OC00ZjEzLTg1N2QtYTM5ZThmYTMzZDkw?sa=X&ved=0CAgQuIEEahcKEwigp7D99MT9AhUAAAAAHQAAAAAQLA",
                    spotify:"https://open.spotify.com/episode/1qOvljtKfXM6WwtX6Dhalr?si=7ae56b27a43f47d5"
                },
                episode_3:{
                    episode_number:"3",
                    episode_title:"Texas Million Movie",
                    episode_date:"22/02/23",
                    episode_duration:"1H 5M",
                    episode_abstract:"With our foreheads set to the floor, we solemnly swear that we will never produce such a garbage episode after skipping a week. Have mercy on Seth’s tortured soul which was hurled into catastrophe as a consequence of piecing together the dismembered mangled limbs that constitute this episode. If you listen closely our nails can be heard scraping against the tarmac in desperate attempt to collect our thoughts on Underground Asian cuisine spots, trauma bond on Bus Public transport, grieve for our inability to find salvation in leather trousers, mindlessly babble about leather jackets, & drivel on for far too long on Fashion tangents. I have never felt closer to death than I have when re-listening to this podcast but I hope you can at least find sanctuary in our ineptitude.",
                    episode_featuring:[],
                    episode_ft_link:[],
                    applecasts:"https://podcasts.apple.com/se/podcast/thedogsense/id1668189499?i=1000601076783",
                    google:"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw/episode/NjQzYTQwZDktNjczZi00MzEyLTkwZTctMWQ1NTY2YTBhZjNm?sa=X&ved=0CAUQkfYCahcKEwjI7rm5srb9AhUAAAAAHQAAAAAQHg",
                    spotify:"https://open.spotify.com/episode/0OYow9cS3Q83RNqYCqd3XG?si=66be6d83f7644612"
                },
                episode_2:{
                    episode_number:"2",
                    episode_title:"WhatWouldJesusWearToday",
                    episode_date:"08/02/23",
                    episode_duration:"1H 29M",
                    episode_abstract:"Eyes rolled back & limbs sprawled across a lino floor, a state not even a mother could love- is where we find ourselves in this cesspool of a podcast. Allow your axons to cook as the fit takes hold & we stammer sweet nothings about our begotten experiences on & off line. This episode we lament the existence of non-e-commerce Artists, despise trappings of online Fashion designer Cults, extrude destain for Drop Shipping culture, explore the semiotics of Fit authentication cops, plea for vigilance when cycling in shorts, relapse on our formative experiences before our spittle coated lips dry & we gargle out our favourite films.",
                    episode_featuring:[],
                    episode_ft_link:[],
                    applecasts:"https://podcasts.apple.com/se/podcast/thedogsense/id1668189499?i=1000598681656",
                    google:"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw/episode/ZmI3ZTU0ZGItNzRjYy00OGFmLWJhMGMtOTIzYjFhOTJmNGY2?sa=X&ved=0CAUQkfYCahcKEwiohvnDvYP9AhUAAAAAHQAAAAAQAQ",
                    spotify:"https://open.spotify.com/episode/4W1NAhIGsu9EudBeYV2X8G?si=f1bd8708ca394c63"
                },
                episode_1:{
                    episode_number:"1",
                    episode_title:"Boot Beach",
                    episode_date:"30/01/23",
                    episode_duration:"1H 23M",
                    episode_abstract:"Kicking off",
                    episode_featuring:[],
                    episode_ft_link:[],
                    applecasts:"https://podcasts.apple.com/se/podcast/thedogsense/id1668189499?i=1000597691076",
                    google:"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw/episode/ZmI3ZTU0ZGItNzRjYy00OGFmLWJhMGMtOTIzYjFhOTJmNGY2?sa=X&ved=0CAUQkfYCahcKEwiohvnDvYP9AhUAAAAAHQAAAAAQAQ",
                    spotify:"https://open.spotify.com/episode/67pfQS6DevUtHFhuwE4ta4?si=1d823ec273424f3c"
                }
            }
        };
        this.togglePodAboutPage = this.togglePodAboutPage.bind(this);
        this.togglePodSpecificEpisode = this.togglePodSpecificEpisode.bind(this);
    }

    componentDidMount(){
        this.togglePodSpecificEpisode(this.state.last_episode)
    }

    togglePodAboutPage() {
        this.setState({
            show_pod_info:!this.state.show_pod_info
        })

    }
    
    togglePodSpecificEpisode(the_episode_stuff) {
        this.setState({
            show_pod_info:true,
            content_episode: the_episode_stuff
        })
        if (this.state.pod_episodes[the_episode_stuff]["episode_featuring"].length>0){
            this.setState({
                episode_has_feature:true,
            })
        } else {
            this.setState({
                episode_has_feature:false,
            })
        }
    }

    render() {
        var this_last_episode = this.state.last_episode

        var podshow = "show"
        var aboutshow = "hide"
        if (this.state.show_pod_info){
            podshow = "show"
            aboutshow = "hide"
        } else {
            podshow = "hide"
            aboutshow = "show"
        }

        var ftshow = ""
        var featuring_content = []
        if (this.state.episode_has_feature){
            ftshow = "show"
            featuring_content = []
            featuring_content.push(this.state.pod_episodes[this.state.content_episode]["episode_featuring"])
        } else {
            ftshow = "hide"
            featuring_content = []
        }

        var podcast_episode_list = []
        for (const podepisode in this.state.pod_episodes) {
            var this_pod_episode = this.state.pod_episodes[podepisode]
            if (this_pod_episode["episode_featuring"].length>0) {
                var feature_format = [];
                for (let i=0; i<this_pod_episode["episode_featuring"].length;i++){
                    feature_format.push(<a href={this_pod_episode["episode_ft_link"][i]} target="_blank" rel="noreferrer">{this_pod_episode["episode_featuring"][i]}</a>)
                }
                podcast_episode_list.push(
                    <div key={"EntryCont_"+podepisode} className="podEntryCont" onClick={() => {this.togglePodSpecificEpisode(podepisode);}}>
                        <div key={"EntryNumber_"+podepisode} className="podEntryNumber">
                            #{this_pod_episode["episode_number"]}
                        </div>
                        <div key={"EntryTitle_"+podepisode} className="podEntryTitle">
                            {this_pod_episode["episode_title"]}
                        </div>
                        <div key={"EntryDate_"+podepisode} className="podEntryDate">
                            {this_pod_episode["episode_date"]}
                        </div>
                        <div key={"EntryTime_"+podepisode} className="podEntryTime">
                            {this_pod_episode["episode_duration"]}
                        </div>
                        <div className="podEntryAbstract">
                            <div className="podEntryFeatures">Featuring {feature_format}</div>
                            {this_pod_episode["episode_abstract"]}
                        </div>
                    </div>
                )
            } else {
                podcast_episode_list.push(
                    <div key={"EntryCont_"+podepisode} className="podEntryCont" onClick={() => {this.togglePodSpecificEpisode(podepisode);}}>
                        <div key={"EntryNumber_"+podepisode} className="podEntryNumber">
                            #{this_pod_episode["episode_number"]}
                        </div>
                        <div key={"EntryTitle_"+podepisode} className="podEntryTitle">
                            {this_pod_episode["episode_title"]}
                        </div>
                        <div key={"EntryDate_"+podepisode} className="podEntryDate">
                            {this_pod_episode["episode_date"]}
                        </div>
                        <div key={"EntryTime_"+podepisode} className="podEntryTime">
                            {this_pod_episode["episode_duration"]}
                        </div>
                        <div className="podEntryAbstract">
                            {this_pod_episode["episode_abstract"]}
                        </div>
                    </div>
                )
            }
        }
        
        var feature_block = [];
        if (this.state.pod_episodes[this.state.content_episode]["episode_featuring"].length > 0) {
            for (let i=0; i<this.state.pod_episodes[this.state.content_episode]["episode_featuring"].length;i++){
                feature_block.push(<a href={this.state.pod_episodes[this.state.content_episode]["episode_ft_link"][i]} target="_blank" rel="noreferrer">{this.state.pod_episodes[this.state.content_episode]["episode_featuring"][i]}</a>)
            }
        }

        return (
            <div className="App">
                <div className="podBigContentCont">
                    <div className="podTitleCont">
                        <div className="podTitle">
                            thedogsense
                        </div>
                    </div>
                    <div className="podContentCont">
                        <div id="podContent" className={podshow}>
                            <div className="podContentReleaseDate">{this.state.pod_episodes[this.state.content_episode]["episode_date"]}</div>
                            <div className="podContentEpisodeTitle">{this.state.pod_episodes[this.state.content_episode]["episode_title"]}</div>
                            <div className="podContentEpisodeNumberDuration">
                                <div className="podContentEpisodeNumber">Episode {this.state.pod_episodes[this.state.content_episode]["episode_number"]}</div>
                                <div className="podContentEpisodeDuration">{this.state.pod_episodes[this.state.content_episode]["episode_duration"]}</div>
                            </div>
                            <div id="podContentEpisodeFeaturing" className={ftshow}>Featuring {feature_block}</div><br/>
                            <div className="podContentEpisodeAbstract">{this.state.pod_episodes[this.state.content_episode]["episode_abstract"]}<br/><br/>
                            Listen to it on <a href={this.state.pod_episodes[this.state.content_episode]["applecasts"]} target="_blank" rel="noreferrer">Apple</a>, <a href={this.state.pod_episodes[this.state.content_episode]["google"]} target="_blank" rel="noreferrer">Google</a> or <a href={this.state.pod_episodes[this.state.content_episode]["spotify"]} target="_blank" rel="noreferrer">Spotify</a></div>
                        </div>
                        <div id="podAboutContent" className={aboutshow}>
                            thedogsense is a <i>international podcast</i> that swaddles in the begotten trenches of Online Luxury Clothing Culture. It is hosted by two ingrates: <a href="https://alirazzak.info/" target="_blank" rel="noreferrer">Ali</a> & <a href="https://www.instagram.com/vincentoliverseth/" target="_blank" rel="noreferrer">Seth</a> who have devoted substantial white matter to the anthropological art of shitposting online.
                            <br/><br/>
                            Join us as we lock our heads between our knees & cast our gaze into the depths of a festering porcelain throne, muttering away the shell shock.
                            <br/><br/>
                            Tune into the show on <a href="https://podcasts.apple.com/se/podcast/the-dog-sense/id1668189499?l=en" target="_blank" rel="noreferrer">Apple</a>, <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw" target="_blank" rel="noreferrer">Google</a> or <a href="https://open.spotify.com/show/1Z13QU3wRRGTsJcUglH1sh" target="_blank" rel="noreferrer">Spotify</a><br/>
                            Contact us via <u>thedogsense@gmail.com</u>
                        </div>
                    </div>
                    <div className="podSocialsCont">
                        <div className="podSocials">
                            <div className="podSelf">
                                <div className="podAboutLogo" onClick={() => {this.togglePodAboutPage();}}>
                                    <img alt="about_logo" src={aboutlogo}/>
                                </div>
                            </div>
                            <div className="podExternal">
                                <div className="podDiscordLogo" style={{display:"none"}}>
                                    <img alt="discord_logo" src={discordlogo}/>
                                </div>
                                <div className="podSpotifyLogo">
                                <a href="https://open.spotify.com/show/1Z13QU3wRRGTsJcUglH1sh" target="_blank" rel="noreferrer"><img alt="spotify_logo" src={spotifylogo}/></a>
                                </div>
                                <div className="podGoogleLogo">
                                    <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kYTM0NGFjNC9wb2RjYXN0L3Jzcw" target="_blank" rel="noreferrer"><img alt="google_logo" src={googlelogo}/>
                                    </a>
                                </div>
                                <div className="podAppleLogo">
                                    <a href="https://podcasts.apple.com/se/podcast/the-dog-sense/id1668189499?l=en" target="_blank" rel="noreferrer"><img alt="podcasts_logo" src={podcastslogo}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="podListContCont">
                    <div className="podListCont">
                        <div className="podListTitle">All Episodes - {this.state.pod_episodes[this_last_episode]["episode_number"]}</div>
                        <div className="podEpisodesListMobile">
                            {podcast_episode_list}
                        </div>
                    </div>
                </div>
                    <div className="podFooterCont">
                        <div className="podFooter">
                            thedogsense #{this.state.pod_episodes[this.state.last_episode]["episode_number"]}: <span className="digifont">{this.state.pod_episodes[this.state.last_episode]["episode_title"]}</span>
                        </div>
                    </div>
            </div>
        )
    }
}